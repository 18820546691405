import cn from 'classnames';
import React, { memo } from 'react';

import { Ad } from 'common/components/Ad';
import { ErrorBoundary } from 'common/components/ErrorBoundary';
import Banners from 'config/constants/banner/banners';
import { PuidsType } from 'config/constants/common';

import s from './styles.module.css';

type WidgetsListBasePropsType = {
  puids: PuidsType;
  children: React.ReactNode[];
};

/**
 * Базовый компонент списка виджетов для второй колонки.
 * @param puids – объект пуидов для рекламы;
 * @param children - массив дочерних виджетов, которые будут рисоваться внутри.
 */
export const WidgetsListBase = memo(
  ({ puids, children }: WidgetsListBasePropsType) => (
    <>
      <ErrorBoundary componentName="WidgetsListBase0">
        {children[0]}
      </ErrorBoundary>

      <ErrorBoundary componentName="WidgetsListBase1">
        {children[1]}
      </ErrorBoundary>

      <ErrorBoundary componentName="WidgetsListBase2">
        {children[2]}
      </ErrorBoundary>

      <Ad
        name={Banners.Context}
        puids={puids}
        className={cn(s.adPlaceholder, 'banner--native-context_widget')}
        onRenderClassName={s.adPlaceholderLoaded}
        isLazy={false}
      />

      <ErrorBoundary componentName="WidgetsListBase3">
        {children[3]}
      </ErrorBoundary>

      <ErrorBoundary componentName="WidgetsListBase4">
        {children[4]}
      </ErrorBoundary>

      <ErrorBoundary componentName="WidgetsListBase5">
        {children[5]}
      </ErrorBoundary>

      <ErrorBoundary componentName="WidgetsListBase6">
        {children[6]}
      </ErrorBoundary>
    </>
  ),
);
